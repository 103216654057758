.btn {
  cursor: pointer;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 100px;
  font-family: inherit;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.fullWidth {
  width: 100%;
}
.sizeLMinW {
  padding: 14px 24px;
  min-width: 140px;
}
.sizeXL {
  padding: 19px 0px;
}

.sizeL {
  padding: 14px 24px;
}
.sizeM {
  padding: 9px 19px;
}
.sizeS {
  padding: 6px 16px;
}
.size0 {
  padding: 0;
}
.filled {
  background: linear-gradient(90deg, #2f5f3c 0%, #108f34 63%, #59be40 100%),
    linear-gradient(90deg, #62b032 0%, #10a93b 63%, #59be40 100%);
  box-shadow: 0px 4px 40px 0px rgba(89, 190, 64, 0.24);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &[disabled] {
    pointer-events: none;
    background: linear-gradient(90deg, #23491a 0%, #0e762c 51%, #23491a 100%),
      linear-gradient(90deg, #28342c 0%, #334036 63%, #395742 100%),
      linear-gradient(90deg, #343343 0%, #363169 63%, #302d52 100%);
    box-shadow: none;

    & > * {
      color: #ffffff;
    }
  }
}

.filledSec {
  background: var(--primary-accent-gradient);
  box-shadow: 0px 4px 80px 0px rgba(255, 149, 24, 0.4);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &[disabled],
  &[aria-disabled="true"] {
    background: rgba(61, 117, 48, 0.32);
    box-shadow: none;

    & > * {
      color: #9cdb8b;
    }
  }

  &[disabled] {
    pointer-events: none;
  }

  &[aria-disabled="true"] {
    cursor: not-allowed;
  }
}

.filledSecV2 {
  background: var(--primary-accent-gradient-v2);
}

.filledSecV3 {
  background: var(--primary-accent-gradient-v3);
}

.filledBull {
  background: linear-gradient(261deg, #ea3800 0%, #af0601 100%);
  box-shadow: 0px 4px 80px 0px rgba(#af0601, 0.4);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  &[disabled] {
    pointer-events: none;
    background: rgba(61, 117, 48, 0.32);
    box-shadow: none;

    & > * {
      color: #9cdb8b;
    }
  }
}
.filledThird {
  border-radius: 16px !important;
  background: linear-gradient(261deg, #ff772b 0%, #ec5500 100%);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  &[disabled] {
    pointer-events: none;
    background: rgba(61, 117, 48, 0.32);
    box-shadow: none;

    & > * {
      color: #9cdb8b;
    }
  }
}
.filledYell {
  border-radius: 16px !important;
  background: #ead40e !important;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  & > * {
    color: #274523 !important;
  }

  &[disabled] {
    pointer-events: none;
    background: rgba(61, 117, 48, 0.32);
    box-shadow: none;

    & > * {
      color: #9cdb8b;
    }
  }
}

.filledAccent {
  background-color: var(--primary-accent-color);
  cursor: pointer;

  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  white-space: nowrap;

  &[aria-disabled="true"],
  &[disabled] {
    opacity: 0.5;

    & > * {
      color: #ffffff;
    }
  }

  &[disabled] {
    pointer-events: none;
  }

  &[aria-disabled="true"] {
    cursor: not-allowed;
  }
}

.filledAccentV2 {
  background-color: var(--secondary-accent-color);
  cursor: pointer;

  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  white-space: nowrap;

  &[aria-disabled="true"],
  &[disabled] {
    background-color: none;

    & > * {
      color: #ffffff;
    }
  }

  &[disabled] {
    pointer-events: none;
  }

  &[aria-disabled="true"] {
    cursor: not-allowed;
  }
}
.filledGift {
  background-color: #ff9900;
  border: 2px solid transparent;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-image: linear-gradient(45deg, #ffc739, #ff7223);
  background-clip: border-box;
  overflow: hidden;

  &[disabled] {
    pointer-events: none;
    background-color: #3d7530;
    opacity: 0.33;
  }
}
.outlined {
  border-radius: 16px !important;
  border: 2px solid #41a105;
  background: transparent;
  & > * {
    color: #41a105 !important;
  }
  &[disabled] {
    pointer-events: none;
    background: rgba(61, 117, 48, 0.32);
    box-shadow: none;

    & > * {
      color: #9cdb8b;
    }
  }
}
.transparent {
  background: transparent;
  border: none;
  box-shadow: none;
  & > * {
    color: #ffffff;
  }
}

.iconUp {
  background-color: transparent;
  flex-flow: column nowrap;
  gap: 0;
  padding: 0 16px;
}
.icon {
  background-color: transparent;
  flex-flow: column nowrap;
  gap: 0;
}
