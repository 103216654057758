.mainContainer {
  position: relative;
}
.tapContainer {
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;
}
.tapContainerText {
  display: flex;
  flex-flow: column nowrap;
  gap: 2px;
}
.infoWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}
.tapInfoContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  & .tapInfoCounter {
    flex: 1;
    padding: 8px;
    background-color: rgba(250, 185, 21, 0.1);
    border-radius: 12px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
}
.tapInfoLeft {
  padding: 8px 24px;
  border-radius: 36px;
  background: #264522;
  margin: 0 auto;
  color: #ffffff;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: width 0.3s;
  &::before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    background: linear-gradient(180deg, #f90 0%, #ec5500 100%);
    z-index: -1;
    border-radius: 36px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #264522;
    z-index: -1;
    border-radius: 36px;
  }
}
.tapInfoLeftActive {
  &::before {
    opacity: 1;
  }
}
.tapBtnContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 0 16px 0 16px;
  position: relative;
}
.particle {
  border-radius: 100px;
  background: linear-gradient(257deg, #f90 16.49%, #ec7100 82.59%), #ff772b;
  box-shadow: 0px 12px 24px 0px rgba(184, 96, 13, 0.4);
  position: absolute;
  left: 50%;
  top: 10px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  z-index: 5;
  color: #ffffff;
  opacity: 0;
}
.particleUpgraded {
  background: #ff3737;
  box-shadow: 0px 12px 24px 0px rgba(95, 3, 3, 0.8),
    0px 12px 24px 0px rgba(95, 3, 3, 0.8),
    0px 12px 24px 0px rgba(184, 96, 13, 0.4);
}
.particleUpgradedMid {
  background: #fa0000;
}
.particleUpgradedHigh {
  background: #cd0000;
}
.claimContainer {
  background: rgba(61, 117, 48, 0.32);
  border-radius: 100px;
  padding: 8px 8px 8px 24px;
  height: 70px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  position: relative;
  color: #ffffff;
}
.claimContainerTap {
  background: linear-gradient(90deg, #23491a 0%, #0e762c 51%, #23491a 100%);
  justify-content: center;
  padding: 0 12px;
}
.claimContainerInvite {
  border: var(--primary-border);
}
.claimContainerBorder {
  border: var(--primary-border);
  padding-right: 6px;
}
.tapCoin {
  max-width: 245px;
}
.tapCoinDisabled {
  opacity: 0.5;
}
.animate {
  animation: shaking 0.6s ease-in-out;
}
.counter {
  font-size: 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  pointer-events: none;
}

@keyframes shaking {
  0% {
    transform: scale(1, 1);
  }
  33% {
    transform: scale(1.08, 0.93);
  }
  66% {
    transform: scale(0.93, 1.08);
  }
  100% {
    transform: scale(1, 1);
  }
}

.tapButton {
  max-width: 245px;
  position: relative;
  z-index: 1;
}

.backCircle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-radius: 10000px;
  background: linear-gradient(180deg, #f90 0%, #ec5500 100%), #f98201;
  transition: width 0.3s, height 0.3s;
}

.backCircleUpgraded {
  background: radial-gradient(50% 50% at 50% 50%, #f90 72.5%, #ec3900 100%);
}

.backShine {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 297px;
  height: 151px;
  border-radius: 307px;
  background: #32c61a;
  filter: blur(40px);
}
.backShineUpgraded {
  background: #e94600;
}

.arrowLeft {
  position: absolute;
  top: 50%;
  left: -4px;
  transform: translateY(-50%);
}

.arrowRight {
  position: absolute;
  top: 50%;
  right: -4px;
  transform: translateY(-50%) rotate(180deg);
}

.arrowBottom {
  transform: rotate(90deg);
}

.inviteButton {
  height: 100%;
  margin-left: auto;
  margin-right: 12px;
}

.shareButton {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: #41a105;
}

.infoActions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.soundButton {
  width: 40px;
  height: 40px;
  background: rgba(61, 117, 48, 0.5);
  border-radius: 100px;
  border: none;
  transition: background 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  & > svg {
    transition: opacity 0.3s;
  }
}

.soundButtonOff {
  background: rgba(61, 117, 48, 0.32);
  & > svg {
    opacity: 0.3;
  }
}

.drumUpgradeButton {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: linear-gradient(268deg, #f90 0%, #f66b07 100%);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  &::after {
    content: "";
    position: absolute;
    top: -4px;
    left: -4px;
    background-color: #f00;
    border: 2px solid #1f331e;
    width: 16px;
    height: 16px;
    border-radius: 100px;
  }
}

.buttonIcon {
  min-width: 20px;
}

.infoWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

// .tapInfoLeft {
//   text-align: center;
//   color: white;
//   font-weight: 600;
// }

.skillsIcon,
.drumsIcon {
  width: 40px;
  height: 40px;
  //background: var(--primary-accent-gradient);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ff9900;
  border-radius: 100% !important;
}

// Header container
.headerContainer {
  display: flex;
  justify-content: space-between;
  height: 41px;
  margin-bottom: 12px;
}

.headerNav {
  display: flex;

  align-items: center;
  gap: 16px;
}

.faqButton {
  max-height: 32px;

  z-index: 2;
}

.disabledBackground {
  position: absolute;
  top: 0;

  width: 100vw;
  height: 100vh;
  margin: 0 -16px;

  background: linear-gradient(180deg, rgba(37, 66, 32, 0.5) 0%, #19291d 100%);

  z-index: 1;
}
